import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Row, Col, Table, Tooltip, Image, Divider } from 'antd';
import { PlusCircleFilled, EditOutlined } from '@ant-design/icons';
import { } from '@ant-design/icons';
import { UseGetAllNews } from '~/services/queries/tintucQuery';
const DanhSachTinTuc = () => {
  const navigate = useNavigate();
  const { data: listnews, isLoading } = UseGetAllNews();


  const addNewBaiviet = () => {
    navigate(`/tin-tuc/tao-bai-viet`);
  };
  const columns = [
    {
      title: 'Avatar',
      render: (listnews) => {
        return <Image src={`https://restapi.quiz.edu.vn/api_kientruc/v1/anhtinbai/img${listnews.id}.jpg?v=${listnews.imglink}`} />;
      },
      width: '90px',
    },
    {
      title: 'Tiêu đề',
      key: 'title',
      render: (e) => (
        <Link to={`/tin-tuc/chi-tiet/${e.key}`}>{e.title}</Link>
      ),
    },
    {
      title: 'cate',
      dataIndex: 'cate',
      key: 'cate',
    },
    {
      title: 'Thao tác',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (e) => (
        <Tooltip title="Sửa bài viết">
          <Button
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => {
              navigate(`/tin-tuc/sua-bai-viet/${e.id}`);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Divider />
      <Row gutter={[16, 32]}>
        <Col span={24}>
          <Button type="primary" style={{ marginBottom: 16 }} icon={<PlusCircleFilled />} onClick={() => addNewBaiviet()}>
            Thêm bài viết
          </Button>
          <Table loading={isLoading} columns={columns} dataSource={listnews} pagination={{ position: 'bottomRight' }} />
        </Col>
      </Row>
    </>
  );
};

export default DanhSachTinTuc;
