import * as httpRequest from '~/utils/httpRequest';

export const getlogs = async () => {
    try {
        const res = await httpRequest.get(`https://restapi.quiz.edu.vn/api_kientruc/v1/getlogs`, {
            params: {},
        });
        return res.items;
    } catch (error) {
        console.log(error);
    }
};

export const getUserDetailByEmail = async (email) => {
  try {
    const res = await httpRequest.get(`https://restapi.quiz.edu.vn/api_kientruc/v1/nguoidungdetail/${email}`, {
      params: {},
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const editUser = async (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append('first_name', data.first_name);
    bodyFormData.append('last_name', data.last_name);
    bodyFormData.append('username', data.username);
    bodyFormData.append('avatar', data.avatar);
    try {
        const res = await httpRequest.post(`https://restapi.quiz.edu.vn/api_kientruc/v1/edituser/${data.id}`, bodyFormData);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const changPassUser = async (data) => {
	var bodyFormData = new FormData();
	bodyFormData.append('password', data.password);
	bodyFormData.append('confirmpass', data.confirm);
	try {
	  const res = await httpRequest.post(`https://restapi.quiz.edu.vn/api_kientruc/v1/changepassnguoidung/${data.email}`, bodyFormData);
	  return res;
	} catch (error) {
	  console.log(error);
	}
  };
