import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Row, Col, Space, Divider, Card, Form, Select, Upload } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import QuillEditor from '~/components/QuillEditor';
import ImgCrop from 'antd-img-crop';
import Resizer from 'react-image-file-resizer';
import TextArea from 'antd/es/input/TextArea';
import { showResultRequest } from '~/services/libsCustom';
import { addnews } from '~/services/tintucService';
const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 16,
    },
};

const Taobaiviet = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [cate, setCate] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [ava64, setAva64] = useState('');
    const onGenderChange = (value) => {
        setCate(value);
    };
    const onFinish = async (values) => {
        values.cate = cate;
        values = {
            ...values,
            image: String(ava64),
        };
        const result = await addnews(values);
        showResultRequest(result);
        navigate('../../tin-tuc/danh-sach-tin-tuc');
    };
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
        Resizer.imageFileResizer(
            file,
            500,
            300,
            'JPEG',
            100,
            0,
            (uri) => {
                setAva64(uri);
            },
            'base64',
            250,
            150,
        );
    };
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    return (
        <>
            <Row gutter={[16, 32]}>
                <Col span={24}>
                    <Card>
                        <Form {...layout} form={form} onFinish={onFinish}>
                            <Form.Item
                                name="title"
                                label="Tiêu đề tin"
                                rules={[{ required: true, message: 'Nhập nội dung' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item label="Danh mục tin">
                                <Select onChange={onGenderChange} allowClear>
                                    <Select.Option value="camnangnhadep">Cẩm nang nhà đẹp</Select.Option>
                                    <Select.Option value="chiasekienthuc">Chia sẻ kiến thức</Select.Option>
                                    <Select.Option value="phongthuy">Phong thủy</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="image" label="Ảnh">
                                <ImgCrop quality={1} aspect={5 / 3} resize={{ width: 200, height: 100 }}>
                                    <Upload
                                        listType="picture-card"
                                        customRequest={dummyRequest}
                                        fileList={fileList}
                                        onChange={onChange}
                                    >
                                        {fileList.length < 1 && '+ Upload'}
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label="Mô tả bài viết"
                                rules={[{ required: true, message: 'Nhập nội dung' }]}
                            >
                                <TextArea />
                            </Form.Item>
                            <QuillEditor label="Chi tiết" />
                            <Divider />

                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
                                <Button
                                    icon={<ArrowLeftOutlined />}
                                    onClick={() => {
                                        navigate('../../tin-tuc/danh-sach-tin-tuc');
                                    }}
                                >
                                    Quay lại
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Cập nhật
                                </Button>
                            </Space>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default Taobaiviet;
