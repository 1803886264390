import { Form } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link'],
        ['image'],
        ['clean'],
    ],
};
const moduleSimple = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        ['link'],
    ],
};
function QuillEditor({ simple, label, form }) {
    const congif = simple ? moduleSimple : modules;

    return (
        <>
            <Form.Item name="formDescription" label={label} initialValue="" form={form}>
                <ReactQuill modules={congif} theme="snow" preserveWhitespace={true} error="" />
            </Form.Item>
        </>
    );
}

export default QuillEditor;
