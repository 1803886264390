import { useQuery } from 'react-query';
import { getlogs, getUserDetailByEmail } from '~/services/sysService';

export function UsegetUserDetail(email) {
    const { data, isLoading } = useQuery(['getUserDetailByEmail', email],() => getUserDetailByEmail(email), {
        refetchOnWindowFocus: false,
    });
    return  { data, isLoading };
}
export function UseGetlogs() {
    const { data, isLoading } = useQuery('getlogs', () => getlogs(), {
        refetchOnWindowFocus: false,
    });
    return { data, isLoading };
}
