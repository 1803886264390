import { useQuery } from 'react-query';
import { getAllNews, getDetailNews, editNews } from '~/services/tintucService';

export function UseGetAllNews() {
    const { data, isLoading } = useQuery('getalltintuc', () => getAllNews(), {
        refetchOnWindowFocus: false,
    });
    return { data, isLoading };
}

export function UseGetDetailNews(id) {
    const { data, isLoading } = useQuery(['getdetailtintuc', id], () => getDetailNews(id), {
        refetchOnWindowFocus: false,
    });
    return { data, isLoading };
}

export function UseEditCanbo(email) {
    const { data, isLoading } = useQuery(['editNews', email],() => editNews(email), {
        refetchOnWindowFocus: false,
    });
    return  { data, isLoading };
}