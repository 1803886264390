import './login.scss'
import { Card, Form, Input, Button } from 'antd';
import Logo from '~/assets/images/logo.png'
import { useNavigate, useLocation } from 'react-router-dom';
import * as APIServices from '~/services/searchService';
import { setUserSession } from '~/utils/Common';
import { showResultRequest } from '~/services/libsCustom';
function Home () {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const onSubmit = async (values) => {
    const redirectPath = location.state?.path || '/';
    const result = await APIServices.login(values);console.log(result)
        if (result.error === false) {
            setUserSession(result);
            //setUserStore(result);
            return navigate(redirectPath, { replace: true });
        } else {
            showResultRequest(result);
        }
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="site-card-border-less-wrapper">
          <div className="text-center" style={{ marginBottom: 32 }}>
            <img src={Logo} alt="" width={90} style={{ marginBottom: 16 }} />
            <h2>Quản trị nội dung</h2>
          </div>
          <Card bordered={false} style={{ maxWidth: '100%', width: 400 }}>
            <Form
              onFinish={onSubmit} form={form}
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <Form.Item
                label="Tên đăng nhập"
                name="email"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Mật khẩu"
                name="password"
              >
                <Input.Password />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                <Button type="primary" htmlType="submit" size='large' style={{ width: '100%' }}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Home;
