import { useEffect } from 'react';
import { removeUserSession } from '~/utils/Common';
import { useNavigate } from 'react-router-dom';
function Logout() {
    let navigate = useNavigate();
    useEffect(() => {
        removeUserSession();
        return navigate('/');
    }, []);
}

export default Logout;
