import { useState } from 'react';
import { UsegetUserDetail } from '~/services/queries/usersQuery';
import { getUser } from '~/utils/Common';
import { Divider, Tooltip, Avatar, Skeleton, Card, Drawer, Button, Form, Input } from 'antd';
import { EditOutlined, KeyOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons';
import { showResultRequest } from '~/services/libsCustom';
import { changPassUser } from '~/services/sysService';
import EditProfile from './edit';
const { Meta } = Card;
function Profile() {
	const [form] = Form.useForm();
    const currentUser = getUser();
    const [changepass, setChangepass] = useState(false);
    const [editProfile, setEditProfile] = useState(false);
    const { data: userData, isLoading } = UsegetUserDetail(currentUser.email);

    const ChangePass = () => {
        setChangepass(true);
    };
	const onChangpass = async (values) => {
        values = { ...values, email: currentUser.email };
        values = JSON.parse(JSON.stringify(values));
        const result = await changPassUser(values);
        showResultRequest(result);
        setChangepass(false);
    };
	const closeChangepass = () => {
        setChangepass(false);
        setEditProfile(false);
    };
	const handleCancel = () => {
    };
    return (
        <>
            <Divider />
            {userData && (
                <Card
                    style={{
                        width: 300,
                        margin: '16px auto',
                    }}
                    actions={[
                        <Tooltip placement="bottom" onClick={ChangePass} title="Đổi mật khẩu">
                            <KeyOutlined key="changpass" />
                        </Tooltip>,
                        <Tooltip placement="bottom" title="Thay đổi thông tin cá nhân">
                            <EditOutlined onClick={() => setEditProfile(true)} key="edit" />
                        </Tooltip>,
                    ]}
                >
                    <Skeleton loading={isLoading} avatar active>
                        <Meta
                            avatar={<Avatar src={userData.avatar} />}
                            title={userData.first_name + ' ' + userData.last_name}
                            description={userData.email}
                        />
                    </Skeleton>
                </Card>
            )}

            <Drawer
                getContainer={false}
                title='Đổi mật khẩu'
                width={480}
                onClose={closeChangepass}
                open={changepass}
                forceRender
            >
                <Form onFinish={onChangpass} form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Form.Item
                        name="password"
                        label='Mật khẩu mới'
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng nhập mật khẩu mới!',
                            },
                            {
                                type: 'string',
                                min: 6,
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label='Nhập lại mật khẩu'
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng nhập lại mật khẩu!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error('Nhật lại mật khẩu không khớp!'),
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <div className="d-flex space-between">
                            <Button htmlType="button" icon={<StopOutlined />} onClick={handleCancel}>
                                Hủy
                            </Button>
                            <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
                                Lưu
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
            <Drawer
                getContainer={false}
                title='Sửa thông tin cá nhân'
                width={480}
                onClose={closeChangepass}
                open={editProfile}
                forceRender
            >
                <EditProfile finish={closeChangepass} userData={userData} />
            </Drawer>
        </>
    );
}

export default Profile;
