import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Row, Col, Space, Divider, Card, Form } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import QuillEditor from '~/components/QuillEditor';
import { showResultRequest } from '~/services/libsCustom';
import { editPage } from '~/services/pagesService';
import { UseGetDetailPage } from '~/services/queries/pagesQuery';
const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};

const Suabaiviet = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [checkchange, setSheckchange] = useState(true);
    const navigate = useNavigate();
    const { data: chitietbaiviet } = UseGetDetailPage(id);
    useEffect(() => {
        chitietbaiviet &&
            form.setFieldsValue({
                formDescription: chitietbaiviet.detail,
            });
    }, [chitietbaiviet, form, id]);
    const onFinish = async (values) => {
        values.id = id;
        const result = await editPage(values);
        showResultRequest(result);
        navigate(`../../quan-tri-noi-dung/chi-tiet/${id}`);
    };
    const handleFormChange = () => {
        const touchedFields = form.isFieldsTouched();
        console.log(touchedFields);
        setSheckchange(!touchedFields);
    };
    return (
        <>
            <Row gutter={[16, 32]}>
                <Col span={24}>
                    <Card>
                        <Form {...layout} form={form} onFinish={onFinish} onFieldsChange={handleFormChange}>
                            <Space
                                direction="horizontal"
                                style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
                            >
                                <Button
                                    icon={<ArrowLeftOutlined />}
                                    onClick={() => {
                                        navigate(`../../quan-tri-noi-dung/chi-tiet/${id}`);
                                    }}
                                >
                                    Quay lại
                                </Button>
                                <Button type="primary" htmlType="submit" disabled={checkchange}>
                                    Cập nhật
                                </Button>
                            </Space>
                            <Divider />
                            <Form.Item label="Tên trang">{chitietbaiviet?.title}</Form.Item>
                            <QuillEditor label="Chi tiết" />
                            <Divider />

                            <Space
                                direction="horizontal"
                                style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
                            >
                                <Button
                                    icon={<ArrowLeftOutlined />}
                                    onClick={() => {
                                        navigate(`../../quan-tri-noi-dung/chi-tiet/${id}`);
                                    }}
                                >
                                    Quay lại
                                </Button>
                                <Button type="primary" htmlType="submit" disabled={checkchange}>
                                    Cập nhật
                                </Button>
                            </Space>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default Suabaiviet;
