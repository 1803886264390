import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
//import { getToken } from '~/utils/Common';

export const RequireAuth = ({ children }) => {
	const location = useLocation();
	const auth = sessionStorage.getItem('token');

	if (!auth) {
		return <Navigate to="/login" state={{ path: location.pathname }} />;
	}
	return children;
};
