import { UseGetlogs } from '~/services/queries/usersQuery';
import { Table, Divider } from 'antd';
import { useEffect } from 'react';
function Home() {
    useEffect(() => {
        
    });
    const { data, isLoading } = UseGetlogs();
    const columns = [
        {
            title: 'Thời gian',
            dataIndex: 'ngaythang',
            key: 'ngaythang',
        },
        {
            title: 'Người dùng',
            dataIndex: 'author',
            key: 'author',
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            key: 'action',
        },
        {
            title: 'Vào lúc',
            dataIndex: 'thoigian',
            key: 'thoigian',
        },
    ];
    return (
        <>
            <Divider />
            <Table loading={isLoading} columns={columns} dataSource={data} pagination={{ position: 'bottomRight' }} />
        </>
    );
}

export default Home;
