import PropTypes from 'prop-types';
import { Avatar, Dropdown, Typography, Space } from 'antd';
import { PageContainer, ProLayout } from '@ant-design/pro-components';
import {
    UnorderedListOutlined,
    SettingOutlined,
    ShopOutlined,
    UserOutlined,
    LogoutOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import Logo from '~/assets/images/logo.svg';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '~/utils/Common';

const defaultMenus = {
    path: '/',
    routes: [
        {
            path: '/tin-tuc',
			Link: false,
            name: 'Tin tức',
            icon: <ShopOutlined />,
            routes: [
                {
                    path: 'danh-sach-tin-tuc',
                    name: 'Danh sách tin',
                },
                {
                    path: 'tao-bai-viet',
                    name: 'Tạo bài viết mới',
                },
            ],
        },
        {
            path: '/dich-vu',
            Link: false,
            name: 'Dịch vụ',
            icon: <UnorderedListOutlined />,
            routes: [
                {
                    path: 'thietkenoithat',
                    name: 'Thiết kế nội thất',
                },
                {
                    path: 'thietkecongtrinh',
                    name: 'Thiết kế công trình',
                },
                {
                    path: 'thicong',
                    name: 'Thi công',
                },
            ],
        },
        {
            path: '/quan-tri-noi-dung',
            name: 'Quản trị nội dung',
            icon: <SettingOutlined />,
            routes: [
                {
                    path: 'chi-tiet/1',
                    name: 'Giới thiệu',
                },
            ],
        },
        {
            path: '/profile',
            name: 'Thông tin tài khoản',
            icon: <SettingOutlined />,
			hideInMenu: true,
            routes: [
                {
                    path: 'edit',
                    name: 'Sửa thông tin',
                },
            ],
			
        },
    ],
};

const RightContent = ({ data }) => {
    const items = [
        {
            key: '1',
            icon: <UserOutlined />,
            label: <Link to="/profile">Thông tin tài khoản</Link>,
        },
        {
            key: '3',
            icon: <LogoutOutlined />,
            label: <Link to="/logout">Đăng xuất</Link>,
        },
    ];
    return (
        <div>
            <Avatar icon={<UserOutlined />} />{' '}
            <Dropdown menu={{ items }} placement="bottomRight">
                <Typography.Link>
                    <Space className="link-color-white">{data.username} </Space>
                </Typography.Link>
            </Dropdown>
        </div>
    );
};
function DefaultLayout({ children, selected, pageTitle }) {
    const dataUser = useSelector((state) => state.user);
    const [userinfo, setUserinfo] = useState(dataUser);
    const currentUser = getUser();
    const location = useLocation();
    return (
        <ProLayout
            style={{
                minHeight: 500,
            }}
            siderWidth={260}
            fixSiderbar
            location={location}
            route={defaultMenus}
            menuItemRender={(item, defaultDom) => <Link to={item.path}> {defaultDom} </Link>}
            layout="mix"
            logo={Logo}
            title="NamNguyen.Art"
            rightContentRender={() => <RightContent data={currentUser} />}
            breadcrumbRender={(route) =>
                route.map(({ path, ...rest }) => ({ path: `${process.env.PUBLIC_URL}${path}`, ...rest }))
            }
            headerTitleRender={(logo, title, _) => {
                const defaultDom = (
                    <Link to="/">
                        <img src={Logo} />
                        {title}
                    </Link>
                );
                if (document.body.clientWidth < 1400) {
                    return defaultDom;
                }
                if (_.isMobile) return defaultDom;
                return <>{defaultDom}</>;
            }}
        >
            <PageContainer title={pageTitle}>
                <div
                    style={{
                        minHeight: 600,
                    }}
                >
                    {children}
                </div>
            </PageContainer>
        </ProLayout>
    );
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DefaultLayout;
