import * as httpRequest from '~/utils/httpRequest';

export const login = async (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append('email', data.email);
    bodyFormData.append('password', data.password);
    try {
        const res = await httpRequest.post(`https://restapi.quiz.edu.vn/api_kientruc/v1/login`, bodyFormData);
        return res;
    } catch (error) {
        console.log(error);
    }
};