import { message, Avatar, Tooltip } from 'antd';
import React from 'react';

export const layout6_16 = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 16,
    },
};

export function showResultRequest(res) {
    if (res?.error) {
        return message.error(res.message);
    } else {
        return message.info(res.message);
    }
}

export function getAvatarFromArr(data, users) {
	if (data === null) return null;
    var test = data.toString().split(',').map(Number);
    let answer = users
        ? users
              .filter((item) => test.includes(item.id))
              .map((item) => (
                  <Tooltip placement="top" title={item.email} key={item.id}>
                      <Avatar src={item.avatar} />
                  </Tooltip>
              ))
        : null;
    return answer;
}

