import { updateUserSession } from '~/utils/Common';
import ImgCrop from 'antd-img-crop';
import { Button, Form, Input, Upload, Divider } from 'antd';
import { StopOutlined, SaveOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { editUser } from '~/services/sysService';
import Resizer from 'react-image-file-resizer';
import { showResultRequest } from '~/services/libsCustom';
import { useQueryClient } from 'react-query';

function EditProfile({ finish, userData }) { console.log(userData)
    const queryClient = useQueryClient();
    const [form] = Form.useForm();
    const [ava64, setAva64] = useState(userData?.avatar);
    const navigate = useNavigate();
    const onFinish = async (values) => {
        values = { ...values, id: userData.id, avatar: String(ava64), email: userData.email };
        values = JSON.parse(JSON.stringify(values));
        console.log(values);
        updateUserSession({ avatar: String(ava64) });
        const result = await editUser(values);
        showResultRequest(result);
        queryClient.invalidateQueries(['getUserDetailByEmail', userData.email]);
        finish();
    };
    useEffect(() => {
        if (!userData) return;
        form.setFieldsValue({
            first_name: userData.first_name,
            last_name: userData.last_name,
            username: userData.username,
        });
    }, [form, userData]);
    const [fileList, setFileList] = useState(() => {
        if (userData?.avatar) {
            return [
                {
                    uid: '-1',
                    name: 'avatar.png',
                    status: 'done',
                    url: userData.avatar,
                },
            ];
        } else {
            return [];
        }
    });
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        setAva64(newFileList);
    };
    const onPreview = async (file) => {
        let src = file.url;

        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);

                reader.onload = () => resolve(reader.result);
            });
        }

        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
        Resizer.imageFileResizer(
            file,
            100,
            100,
            'JPEG',
            100,
            0,
            (uri) => {
                console.log(uri);
                setAva64(uri);
            },
            'base64',
            100,
            100,
        );
    };
    const handleCancel = () => {
        navigate(`/users/${userData.email}`);
    };
    return (
        <>
            <Form
                name="nest-messages"
                onFinish={onFinish}
                form={form}
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
            >
                <Form.Item name="Avatar" label="Avatar">
                    <ImgCrop rotate>
                        <Upload
                            customRequest={dummyRequest}
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={onPreview}
                        >
                            {fileList.length < 1 && '+ Upload'}
                        </Upload>
                    </ImgCrop>
                </Form.Item>

                <Divider dashed />
                <Form.Item
                    name="first_name"
                    label="First Name"
                    rules={[{ required: true, message: 'insert first name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="last_name" label="Last Name" rules={[{ required: true, message: 'insert last name' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="username" label="User Name" rules={[{ required: true, message: 'insert user name' }]}>
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <div className="d-flex space-between">
                        <Button htmlType="button" icon={<StopOutlined />} onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
                            Submit
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </>
    );
}
export default EditProfile;
