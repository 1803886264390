import * as httpRequest from '~/utils/httpRequest';

export const getDichvu = async (type) => {
    try {
        const res = await httpRequest.get(`https://restapi.quiz.edu.vn/api_kientruc/v1/dichvu/${type}`, {
            params: {},
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const getDetailDichVu = async (id) => {
    try {
        const res = await httpRequest.get(`https://restapi.quiz.edu.vn/api_kientruc/v1/dichvudetail/${id}`, {
            params: {},
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const editDichvu = async (data) => {
    var bodyFormData = new FormData();
	  bodyFormData.append('title', data.title);
    bodyFormData.append('description', data.description);
    bodyFormData.append('detail', data.formDescription);
	  bodyFormData.append('image', data.image);
    bodyFormData.append('type', data.type);
    bodyFormData.append('cate', data.cate);
    try {
        const res = await httpRequest.post(`https://restapi.quiz.edu.vn/api_kientruc/v1/dichvu/${data.id}`, bodyFormData);
        return res;
    } catch (error) {
        console.log(error);
    }
};