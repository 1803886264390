// Pages
import Login from '~/pages/Login/Login';
import Logout from '~/pages/Logout/';
import Home from '~/pages/Home';
import Profile from '~/pages/Profile/';
import DanhSachTin from '~/pages/tin-tuc/';
import ChitietTin from '~/pages/tin-tuc/chi-tiet';
import TaoBaiViet from '~/pages/tin-tuc/tao-bai-viet';
import SuaBaiViet from '~/pages/tin-tuc/sua-bai-viet';
import DanhSachDichVuByType from '~/pages/dich-vu/';
import ChitietDichVu from '~/pages/dich-vu/chi-tiet';
import SuaDichVu from '~/pages/dich-vu/sua-bai-viet';
import ChitietPage from '~/pages/quan-tri-noi-dung/chi-tiet';
import SuaPage from '~/pages/quan-tri-noi-dung/sua-bai-viet';
import Page404 from '~/pages/Page404';
import Null from '~/layouts/Null';
// Public routes
const publicRoutes = [
    { path: '/login', component: Login, layout: Null },
     { path: '/logout', component: Logout, layout: Null },
    { path: '/*', component: Page404, layout: Null },
];

const privateRoutes = [
    { path: '/', component: Home },
    { path: '/profile', component: Profile },
    { path: '/tin-tuc/danh-sach-tin-tuc', component: DanhSachTin },
    { path: '/tin-tuc/chi-tiet/:id', component: ChitietTin },
    { path: '/tin-tuc/tao-bai-viet', component: TaoBaiViet },
    { path: '/tin-tuc/sua-bai-viet/:id', component: SuaBaiViet },
    { path: '/dich-vu/:id', component: DanhSachDichVuByType },
    { path: '/dich-vu/chi-tiet/:id', component: ChitietDichVu },
    { path: '/dich-vu/sua-bai-viet/:id', component: SuaDichVu },
    { path: '/quan-tri-noi-dung/chi-tiet/:id', component: ChitietPage },
    { path: '/quan-tri-noi-dung/sua-bai-viet/:id', component: SuaPage },
];

export { publicRoutes, privateRoutes };
