import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Row, Col, Space, Card, Spin } from 'antd';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { UseGetDetailNews } from '~/services/queries/tintucQuery';
import { Markup } from 'interweave';
const ChiTietTin = () => {
  const { id } = useParams();
  const { data: detailNews, isLoading } = UseGetDetailNews(id);
  console.log(detailNews);
  const navigate = useNavigate();

  return (
    <>
      {detailNews && (
        <Spin spinning={isLoading}>
          <Card title={detailNews.title} className="mb-10" extra={<Button type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              navigate(`/tin-tuc/sua-bai-viet/${id}`);
            }}>
            Sửa bài viết
          </Button>}>
            <Row>
              <Col span={24}>
                <strong>{detailNews.description}</strong>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Markup content={detailNews.detail} />
              </Col>
            </Row>
          </Card>
        </Spin>
      )}
      <Space wrap style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 24, marginTop: 50 }}>
        <Button
          icon={<ArrowLeftOutlined />}
          onClick={() => {
            navigate('../../tin-tuc/danh-sach-tin-tuc');
          }}
        >
          Quay lại
        </Button>
      </Space>
    </>
  );
};

export default ChiTietTin;
