import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Input, Row, Col, Space, Divider, Card, Form, Select, Upload } from 'antd';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import QuillEditor from '~/components/QuillEditor';
import ImgCrop from 'antd-img-crop';
import Resizer from 'react-image-file-resizer';
import TextArea from 'antd/es/input/TextArea';
import { showResultRequest } from '~/services/libsCustom';
import { editDichvu } from '~/services/dichvuService';
import { UseGetDetailDichVu } from '~/services/queries/dichvuQuery';
const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 18,
  },
};

const Suabaiviet = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [ava64, setAva64] = useState('');
  const [checkchange, setSheckchange] = useState(true);
  const { data: chitietbaiviet, isLoading } = UseGetDetailDichVu(id);
  useEffect(() => {
    chitietbaiviet &&
      form.setFieldsValue({
        title: chitietbaiviet.title,
        formDescription: chitietbaiviet.detail,
        description: chitietbaiviet.description,
        cate: chitietbaiviet.cate,
        type: chitietbaiviet.type,
      });

    setFileList([
      {
        uid: '-1',
        name: `${chitietbaiviet?.title}`,
        status: 'done',
        url: `https:///restapi.quiz.edu.vn/api_kientruc/v1/dichvu/img${id}.jpg?v=${chitietbaiviet?.imglink}`,
      },
    ]);
  }, [chitietbaiviet, form, id]);
  const onFinish = async (values) => {
    values.id = id;
    values = {
      ...values,
      image: String(ava64),
    };
    const result = await editDichvu(values);
    showResultRequest(result);
    navigate(`../../dich-vu/chi-tiet/${id}`);
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
    Resizer.imageFileResizer(
      file,
      500,
      300,
      'JPEG',
      100,
      0,
      (uri) => {
        setAva64(uri);
      },
      'base64',
      250,
      150,
    );
  };
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    setSheckchange(false)
  };
  const handleFormChange = () => {
    const touchedFields = form.isFieldsTouched();
    setSheckchange(!touchedFields)
  };
  return (
    <>
      <Row gutter={[16, 32]}>
        <Col span={24}>
          <Card loading={isLoading}>
            <Form {...layout} form={form} onFinish={onFinish} onFieldsChange={handleFormChange}>
              <Space direction="horizontal" style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  icon={<ArrowLeftOutlined />}
                  onClick={() => {
                    navigate(`../../dich-vu/chi-tiet/${id}`);
                  }}
                >
                  Quay lại
                </Button>
                <Button type="primary" icon={<SaveOutlined />} htmlType="submit" disabled={checkchange}>
                  Cập nhật
                </Button>
              </Space>
              <Divider />
              <Form.Item
                name="title"
                label="Tiêu đề tin"
                rules={[{ required: true, message: 'Nhập nội dung' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Loại dịch vụ" name="type">
                <Select allowClear>
                  <Select.Option value="thietkenoithat">Thiết kế nội thất</Select.Option>
                  <Select.Option value="thietkecongtrinh">Thiết kế công trình</Select.Option>
                  <Select.Option value="thicong">Thi công</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Danh mục" name="cate">
                <Select allowClear>
                  <Select.Option value="bietthu">Biệt thự</Select.Option>
                  <Select.Option value="chungcu">Chung cư</Select.Option>
                  <Select.Option value="nhapho">Nhà phố</Select.Option>
                  <Select.Option value="vanphong">Văn phòng</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="image" label="Ảnh">
                <ImgCrop quality={1} aspect={5 / 3} resize={{ width: 200, height: 100 }}>
                  <Upload
                    listType="picture-card"
                    customRequest={dummyRequest}
                    fileList={fileList}
                    onChange={onChange}
                  >
                    {fileList.length < 1 && '+ Upload'}
                  </Upload>
                </ImgCrop>
              </Form.Item>
              <Form.Item
                name="description"
                label="Mô tả bài viết"
                rules={[{ required: true, message: 'Nhập nội dung' }]}
              >
                <TextArea />
              </Form.Item>
              <QuillEditor label="Chi tiết" />
              <Divider />

              <Space direction="horizontal" style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  icon={<ArrowLeftOutlined />}
                  onClick={() => {
                    navigate(`../../dich-vu/chi-tiet/${id}`);
                  }}
                >
                  Quay lại
                </Button>
                <Button type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={checkchange}>
                  Cập nhật
                </Button>
              </Space>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Suabaiviet;
