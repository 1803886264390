import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Row, Col, Card, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { UseGetDetailPage } from '~/services/queries/pagesQuery';
import { Markup } from 'interweave';
const ChiTietTin = () => {
  const { id } = useParams();
  const { data: detailNews, isLoading } = UseGetDetailPage(id);
  const navigate = useNavigate();

  return (
    <>
      {detailNews && (
        <Spin spinning={isLoading}>
          <Card title={detailNews.title} className="mb-10" extra={<Button type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              navigate(`/quan-tri-noi-dung/sua-bai-viet/${id}`);
            }}>
            Sửa bài viết
          </Button>}>
            <Row>
              <Col span={24}>
                <strong>{detailNews.description}</strong>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Markup content={detailNews.detail} />
              </Col>
            </Row>
          </Card>
        </Spin>
      )}
    </>
  );
};

export default ChiTietTin;
