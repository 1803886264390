import * as httpRequest from '~/utils/httpRequest';

export const getDetailPage = async (id) => {
    try {
        const res = await httpRequest.get(`https://restapi.quiz.edu.vn/api_kientruc/v1/page/${id}`, {
            params: {},
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const editPage = async (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append('detail', data.formDescription);
    try {
        const res = await httpRequest.post(`https://restapi.quiz.edu.vn/api_kientruc/v1/page/${data.id}`, bodyFormData);
        return res;
    } catch (error) {
        console.log(error);
    }
};
