import { useQuery } from 'react-query';
import { getDichvu, getDetailDichVu } from '~/services/dichvuService';

export function UseGetDichVu($id) {
    const { data, isLoading } = useQuery(['getdichvu', $id], () => getDichvu($id), {
        refetchOnWindowFocus: false,
    });
    return { data, isLoading };
}
export function UseGetDetailDichVu(id) {
    const { data, isLoading } = useQuery(['getdetaildichvu', id], () => getDetailDichVu(id), {
        refetchOnWindowFocus: false,
    });
    return { data, isLoading };
}